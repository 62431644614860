<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-[#00263e] print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.default"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex items-center w-full p-4 pl-6">
      <!-- Logo -->
      <div class="flex items-center justify-center">
        <img class="w-30" src="assets/images/logo/alianca-negativo.svg" />
      </div>
      <!-- Components -->
      <div class="flex items-center ml-auto">
        <user [showprofilePicture]="false"></user>
      </div>
    </div>
    <!-- User -->
    <div class="flex flex-col items-center w-full p-4">
      <div
        class="relative w-24 h-24 bg-gray-100 rounded-full ring-2 ring-primary ring-offset-5"
      >
        <span class="absolute -inset-1.5"></span>
        <img
          class="w-full h-full rounded-full object-contain"
          *ngIf="user?.profilePicture"
          [src]="user?.profilePicture"
          alt="User profilePicture"
        />
        <div
          class="relative rounded-full w-24 h-24 bg-gray-100 animate-pulse"
          *ngIf="!user?.profilePicture"
        ></div>
      </div>
      <div class="flex flex-col items-center justify-center w-full mt-6">
        <div
          class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium"
        >
          {{ user?.name }}
        </div>
        <div
          class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
        >
          {{ user?.login }}
        </div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <!-- Components -->
    <div
      *ngIf="isStaging"
      class="w-full text-red-500 text-2xl flex justify-center font-bold"
    >
      AMBIENTE DE TESTES
    </div>
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <languages></languages>
      <notifications></notifications>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Aliança &copy; {{currentYear}}</span>
    </div>-->
</div>
