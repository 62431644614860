import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, of, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { defaultNavigation } from './defaultNavigation';
import { compactNavigation } from './compactNavigation';
import { futuristicNavigation } from './futuristicNavigation';
import { horizontalNavigation } from './horizontalNavigation';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { AuthService } from '../auth/auth.service';
import { InstructorType } from '../../../../api-client';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

  constructor(private _httpClient: HttpClient, private authService: AuthService) {
  }

  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  filter(items: FuseNavigationItem[]): FuseNavigationItem[] {
    for (var item of items) {
      if (item.type == 'group') {
        item.children = this.filter(item.children);
      }
    }

    if (this.authService.isAll()) return items;

    if (this.authService.isEffective()) return items.filter(x => x.instructorType !== InstructorType.Experimental)
    if (this.authService.isExperimental()) return items.filter(x => x.instructorType !== InstructorType.Effective)
  }

  get(): Observable<Navigation> {
    return of(
      <Navigation>{
        default: this.filter(defaultNavigation),
        compact: compactNavigation,
        futuristic: futuristicNavigation,
        horizontal: this.filter(horizontalNavigation)
      }
    ).pipe(
      tap((navigation) => {
        this._navigation.next(navigation);
      })
    )
  }
}
