import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-server-side-select',
  templateUrl: './server-side-select.component.html',
  styleUrls: ['./server-side-select.component.scss']
})
export class ServerSideSelectComponent implements OnInit {
  @Input() loading: boolean;
  @Input() propertyValue: string;
  @Input() propertyText: string;
  @Input() placeholder: string = "Pesquise...";

  @Input() searchSubject: Subject<string>;
  @Input() objectsList: any[] = []

  @Input() label: string = "";

  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();

  public formControl: FormControl = new FormControl(null);
  public filterFormControl: FormControl<string> = new FormControl<string>(null);

  ngOnInit(): void {
    this.filterFormControl.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe({
        next: (value: string) => {
          if (value) {
            this.searchSubject.next(value)
          }
        }
      })

    this.formControl.valueChanges.subscribe({
      next: (value) => {
        this.valueSelected.emit(value)
      }
    })
  }

  getObjectValue(object: any) {
    if (!this.propertyValue) return object;
    return object[this.propertyValue];
  }

  getObjectText(object: any) {
    let returnValue: string;

    for (let text of this.propertyText.split("+")) {

      let value = object;

      for (let p of text.split(".")) {
        value = value[p];
      }


      returnValue = !!returnValue ? [returnValue, value].join(" - ") : value
    }


    return returnValue;
  }
}
