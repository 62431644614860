import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private toastr: ToastrService,
    ) { }

    info(message: string, title: string = null) {
        this.toastr.info(message, title)
    }

    warn(message: string, title: string = null) {
        this.toastr.warning(message, title)

    }

    error(message: string, title: string = null) {
        this.toastr.error(message, title)

    }

    success(message: string, title: string = null) {
        this.toastr.success(message, title)
    }
}
