import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response) => {
        switch (response.status) {
          case 400:
            return this.Handle400(response);
          case 401:
            return this.Handle401(response);
          case 403:
            return this.Handle403(response);
          case 406:
            return this.Handle406(response);
          case 500:
            return this.Handle500(response);
          case 308:
            return this.Handle308(response);
          default:
            return this.Handle500(response);
        }
      })
    );
  }

  private async Handle400(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const errors: string[] = [];

      if (
        response instanceof HttpErrorResponse &&
        response.error instanceof Blob &&
        response.error.type === 'application/problem+json'
      ) {
        try {
          const errorResponse: any = await new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (e: Event) => {
              resolve(JSON.parse((<any>e.target).result));
            };
            reader.onerror = reject;
            reader.readAsText(response.error);
          });

          for (let parentKey in errorResponse.errors) {
            for (let childKey in errorResponse.errors[parentKey]) {
              errors.push(errorResponse.errors[parentKey][childKey]);
            }
          }
          errors.push(errorResponse.detail)
        } catch (e) { }
      }

      if (errors.length) {
        for (let error of errors) {
          console.warn(error);

          this.notificationService.error(error);
        }
      } else {
        this.notificationService.error(
          'Ocorreu um erro na sua requisição. Tente novamente mais tarde.'
        );
      }

      reject(response);
    });
  }

  private async Handle401(response: any): Promise<any> {
    if (this.router.url.indexOf('sign-in') == -1) {
      return new Promise(async (resolve, reject) => {
        window.location.reload();
        reject(response);
      });
    }

    return new Promise(async (resolve, reject) => {
      reject(response);
    });
  }

  private async Handle403(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.notificationService.error(
        'Você não possui permissão para executar essa operação.'
      );

      this.router.navigate(["/"])

      reject(response);
    });
  }

  private async Handle406(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.notificationService.error(
        'Conclua seu cadastro antes de continuar!'
      );

      this.router.navigateByUrl("/first-access")

      reject(response);
    });
  }

  private async Handle500(response: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.notificationService.error('Ocorreu um erro inesperado.')
      reject(response);
    });
  }

  private async Handle308(response: any): Promise<any> {
    if (response instanceof HttpErrorResponse && response.error instanceof Blob) {
        const errorResponse: any = await new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = (e: Event) => {
            resolve(JSON.parse((<any>e.target).result));
          };
          reader.onerror = reject;
          reader.readAsText(response.error);
        });

        const url = errorResponse.detail;

        if(url === 'terms') {
          this.router.navigate([`/${url}`]);
        }
        
        return Promise.reject(response);
    }
      
  }
}
