import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { DragAndDropDirective } from './diretives/drag-and-drop.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VideoUploaderComponent } from './components/video-uploader/video-uploader.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ServerSideSelectComponent } from './components/server-side-select/server-side-select.component';
import { MAT_SELECTSEARCH_DEFAULT_OPTIONS, MatSelectSearchOptions, NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { TranslocoModule } from '@ngneat/transloco';
import { AbbreviateNumberPipe } from './pipes/abbreviate-number.pipe';

const materialModules = [
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRippleModule,
    MatSortModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatTableModule,
    MatSnackBarModule,
    MatDividerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatSidenavModule
]

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        NgxMatSelectSearchModule,

        ...materialModules,

        NgxMaskDirective,
        NgxMaskPipe,

        TranslocoModule

    ],
    providers: [
        {
            provide: MAT_SELECTSEARCH_DEFAULT_OPTIONS,
            useValue: <MatSelectSearchOptions>{
              closeIcon: 'delete',
              noEntriesFoundLabel: 'Nenhum item encontrado',
              placeholderLabel: "Pesquisar"
            }
          }
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        ImageUploaderComponent,
        VideoUploaderComponent,
        FileUploaderComponent,

        ServerSideSelectComponent,

        AbbreviateNumberPipe
    ],
    declarations: [
        ImageUploaderComponent,
        DragAndDropDirective,
        VideoUploaderComponent,
        FileUploaderComponent,
        ServerSideSelectComponent,
        AbbreviateNumberPipe,
    ]
})
export class SharedModule {
}
