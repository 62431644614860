import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, Router, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { API_BASE_URL } from '../../api-client';
import { environment } from 'environments/environment';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe, registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import * as Sentry from "@sentry/angular-ivy";
import { TranslocoRootModule } from './transloco-root.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { IntlTelInputService } from './shared/services/intl-tel-input.service';
import { IonicModule } from '@ionic/angular';
import { VIDEO_API_BASE_URL } from '../../video-api-client';

registerLocaleData(pt);

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled'
};

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

const sentry = environment.production ? [
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
  }, {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => { },
    deps: [Sentry.TraceService],
    multi: true,
  },
] : []

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Fuse, FuseConfig & FuseMockAPI
    FuseModule,
    FuseConfigModule.forRoot(appConfig),

    // Core module of your application
    CoreModule,

    // Layout module of your application
    LayoutModule,

    NgxMaskDirective,
    NgxMaskPipe,

    ToastrModule.forRoot(),

    HttpClientModule,

    TranslocoRootModule,
    TranslocoModule,
    MatDialogModule,
    IonicModule.forRoot()
  ],
  providers: [
    DatePipe,
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl
    },
    {
      provide: VIDEO_API_BASE_URL,
      useValue: environment.videoApiBaseUrl
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },

    { provide: LOCALE_ID, useValue: 'pt-BR' },

    {
      provide: APP_INITIALIZER,
      useFactory: (intlTelInputService: IntlTelInputService) => () => intlTelInputService.initialize(),
      deps: [IntlTelInputService],
      multi: true
    },

    provideEnvironmentNgxMask(),
    provideNgxMask(),


    NgxMaskDirective,
    NgxMaskPipe,
    InfiniteScrollModule,

    ...sentry
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

