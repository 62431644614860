
<mat-form-field class="w-full fuse-mat-dense">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControl]="formControl" [placeholder]="placeholder">
      <mat-option>
        <ngx-mat-select-search [formControl]="filterFormControl" [searching]="loading" ></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let object of objectsList" [value]="getObjectValue(object)">
        {{getObjectText(object)}}
      </mat-option>
    </mat-select>
  </mat-form-field>
