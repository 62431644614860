import { FuseNavigationItem } from "@fuse/components/navigation";
import { InstructorType } from "../../../../api-client";

export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards-dash-home',
        title: 'Início',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/start'
    },
    {
        icon: 'heroicons_outline:computer-desktop',
        id: 'classes',
        title: "Aulas",
        type: 'group',
        children: [
            {
                icon: 'heroicons_outline:computer-desktop',
                id: 'classes-nav',
                type: 'basic',
                title: 'Aulas',
                link: '/classes/list',
                instructorType: InstructorType.Effective
            },
            {
                icon: 'heroicons_outline:computer-desktop',
                id: 'experimetal-class-list',
                type: 'basic',
                title: 'Aulas Experimentais',
                link: '/experimental-classes',
                instructorType: InstructorType.Experimental
            },
        ]
    },

    {
        icon: 'heroicons_outline:users',
        id: 'students-nav',
        title: "Alunos",
        type: 'group',
        children: [
            {
                id: 'students-nav',
                title: 'Alunos',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link: '/students/list',
            },
            {
                id: 'distribution-request',
                title: 'Solicitações',
                type: 'basic',
                icon: 'heroicons_outline:cloud-arrow-down',
                link: '/students/requests',
            },
        ]
    },
    {
        id: 'instructors',
        icon: 'heroicons_outline:academic-cap',
        title: "Professores",
        type: 'group',
        children: [
            {
                id: 'hours-list',
                title: 'Horários',
                type: 'basic',
                icon: 'heroicons_outline:calendar-days',
                link: '/instructors/calendar',

            },
            {
                id: 'calendar-list',
                title: 'Calendário',
                type: 'basic',
                icon: 'heroicons_outline:calendar',
                link: '/instructors/hours',

            },
            {
                id: 'documents-list',
                title: 'Materiais',
                type: 'basic',
                icon: 'heroicons_outline:clipboard-document',
                link: '/documents',
            },
        ]
    },
    {

        id: 'helpdesk',
        title: 'Ajuda',
        type: 'basic',
        icon: 'heroicons_outline:question-mark-circle',
        link: 'http://help.aliancaamerica.com.br',
        externalLink: true,
        target: '_blank',
    }
];
